<template>
    <div>
        <div class="veadmin-iframe-header veadmin-iframe-header-custom-fixed">
            <span>当前位置：</span> 我的桌面
            <button class="veadmin-iframe-button" onclick="location.reload();">
                <i class="el-icon-refresh-right"></i>
            </button>
        </div>
        <div class="veadmin-iframe-content veadmin-iframe-content-custom-fixed">
            <div style="padding: 0px 20px">
                <h1>欢迎你！[{{ user.name }}]</h1>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            user: {
                name: "",
                profile: "",
                portrait: "/pub/images/portrait/default.jpg",
                phone: "",
                email: ""
            }
        };
    },
    methods: {
        getUser() {
            this.user = JSON.parse(this.$store.state.user);
        }
    },
    mounted() {
        this.getUser();
    }
};
</script>
